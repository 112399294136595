import React from 'react';
import { Link } from 'gatsby';

import './Footer.css';
import logo from '../../../images/luxor-management-logo.svg';
import links from '../../../data/links.json';
import Button from '../Button/Button';

const Footer = () => (
  <footer className="c-footer">
    <div className="l-container">
      <nav className="flex flex-col lg:flex-row lg:items-center">
        <div className="c-footer__nav">
          <Link
            to={links.home}
            role="link"
            className="c-footer-nav-link"
            activeClassName="text-brand-primary-l1"
          >
            Home
          </Link>
          <Link
            to={links.residential}
            role="link"
            className="c-footer-nav-link"
            activeClassName="text-brand-primary-l1"
          >
            Residential
          </Link>
          <Link
            to={links.commercial}
            role="link"
            className="c-footer-nav-link"
            activeClassName="text-brand-primary-l1"
          >
            Commercial
          </Link>
          <Link
            to={links.contact}
            role="link"
            className="c-footer-nav-link"
            activeClassName="text-brand-primary-l1"
          >
            Contact
          </Link>
        </div>
        <div className="flex justify-center mt-5 lg:mt-0">
          <Button
            className="mr-6"
            secondary
            label="Maintenance Request"
            link={links.maintenanceRequest}
          />
          <Button label="Apply Now" link={links.applyNow} />
        </div>
      </nav>

      <hr className="divide-border-color my-6" />

      <div className="flex flex-col lg:flex-row">
        <div className="flex items-center justify-center lg:flex-grow lg:justify-start">
          <Link to={links.home}>
            <img
              className="c-footer__logo mr-8"
              src={logo}
              alt="Luxor Management Logo"
            />
          </Link>
          <address className="not-italic">
            <span className="block font-bold text-sm">Head Office</span>
            <span className="text-xs text-brand-medium">
              120 Larch Street
              <br />
              Sudbury, ON, P3E 1C2
            </span>
          </address>
        </div>

        <div className="c-footer__copyright">
          <Link
            className="mb-3 hover:text-brand-primary transition-colors sm:mb-0"
            to={links.privacy}
          >
            Privacy Policy
          </Link>
          <span className="sm:order-first">
            &copy; {new Date().getFullYear()} Luxor Management Inc.
            <span className="hidden mx-2 sm:inline-block">&middot;</span>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
