import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import '../../BaseStyle/Style.css';
import './Layout.css';

const Layout = ({ children }) => (
  <>
    <div className="page-wrap">
      <Header />
      <main className="inner-wrap">{children}</main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
