import React, { useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './Header.css';
import logo from '../../../images/luxor-management-logo.svg';
import links from '../../../data/links.json';
import MenuIcon from '../Icons/24/MenuIcon24';
import CloseIcon from '../Icons/24/CloseIcon24';
import Button from '../Button/Button';
import MobileNav from '../MobileNav/MobileNav';

const Header = () => {
  const [navOpen, toggleMobileNav] = useState(false);

  return (
    <>
      <header className="c-header" role="banner">
        <div className="c-header__container">
          <div className="flex-grow">
            <Link to={links.home}>
              <img
                className="c-header__logo"
                src={logo}
                alt="Luxor Management Logo"
              />
            </Link>
          </div>

          <nav className="hidden justify-end items-end flex-col md:flex">
            <div className="flex">
              <Button
                className="mr-6"
                secondary
                label="Maintenance Request"
                link={links.maintenanceRequest}
              />
              <Button label="Apply Now" link={links.applyNow} />
            </div>
            <div className="flex items-center mt-2">
              <Link
                to={links.home}
                role="link"
                className="c-header-nav-link"
                activeClassName="c-header-nav-link--active"
              >
                <span className="c-header-nav-link__inner">Home</span>
              </Link>
              <Link
                to={links.residential}
                role="link"
                className="c-header-nav-link"
                activeClassName="c-header-nav-link--active"
              >
                <span className="c-header-nav-link__inner">Residential</span>
              </Link>
              <Link
                to={links.commercial}
                role="link"
                className="c-header-nav-link"
                activeClassName="c-header-nav-link--active"
              >
                <span className="c-header-nav-link__inner">Commercial</span>
              </Link>
              <Link
                to={links.contact}
                role="link"
                className="c-header-nav-link"
                activeClassName="c-header-nav-link--active"
              >
                <span className="c-header-nav-link__inner">Contact</span>
              </Link>
            </div>
          </nav>

          <button
            className="c-nav-button"
            aria-label="Mobile Navigation Menu"
            onClick={() => toggleMobileNav((navOpen) => !navOpen)}
          >
            <MenuIcon
              className={classNames('fill-brand-dark block', {
                hidden: navOpen,
              })}
            />
            <CloseIcon
              className={classNames('fill-brand-dark', {
                hidden: !navOpen,
                block: navOpen,
              })}
            />
          </button>
        </div>
      </header>
      <MobileNav isOpen={navOpen} />
    </>
  );
};

export default Header;
