import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './Button.css';

const Button = ({ ...props }) => {
  const classes = classNames(props.className, {
    'c-primary-button': !props.secondary,
    'c-secondary-button': props.secondary,
  });

  if (!props.submit) {
    return (
      <Link role="link" to={props.link} className={classes}>
        {props.label}
      </Link>
    );
  }
  return (
    <button className={classes} type="submit">
      {props.label}
    </button>
  );
};

export default Button;
