import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './MobileNav.css';
import links from '../../../data/links.json';
import Button from '../Button/Button';

const MobileNav = ({ ...props }) => (
  <section
    className={classNames('c-mobile-nav', {
      hidden: !props.isOpen,
      block: props.isOpen,
    })}
  >
    <div className="c-mobile-nav__container">
      <Link
        to={links.home}
        className="py-3 font-bold text-lg text-brand-dark"
        activeClassName="text-brand-primary-l1"
      >
        Home
      </Link>
      <Link
        to={links.residential}
        className="py-3 font-bold text-lg text-brand-dark"
        activeClassName="text-brand-primary-l1"
      >
        Residential
      </Link>
      <Link
        to={links.commercial}
        className="py-3 font-bold text-lg text-brand-dark"
        activeClassName="text-brand-primary-l1"
      >
        Commercial
      </Link>
      <Link
        to={links.contact}
        className="py-3 font-bold text-lg text-brand-dark"
        activeClassName="text-brand-primary-l1"
      >
        Contact
      </Link>
      <div className="flex justify-center mt-5 lg:mt-0">
        <Button
          className="mr-6"
          secondary
          label="Maintenance Request"
          link={links.maintenanceRequest}
        />
        <Button label="Apply Now" link={links.applyNow} />
      </div>
    </div>
  </section>
);

export default MobileNav;
